import moment from "moment";
import configData from "../../chouette-config.json";

export const isDayAllowed = (date) => {
  const mtDate = moment(date);
  const tomorrow = moment().add(1, "days");
  const weekdaysAllowed = configData.WEEKDAYS_ALLOWED;
  const bankHolidays = configData.BANK_HOLIDAYS;

  if (mtDate.format("DD/MM/YY") === "14/02/22") {
    return true;
  }

  if (tomorrow.format("DD/MM/YY") === mtDate.format("DD/MM/YY")) {
    return (
      moment().hour() <= 21 &&
      weekdaysAllowed.includes(mtDate.day()) &&
      !bankHolidays.includes(mtDate.format("DD/MM/YYYY"))
    );
  }

  return (
    weekdaysAllowed.includes(mtDate.day()) &&
    !bankHolidays.includes(mtDate.format("DD/MM/YYYY"))
  );
};

export const getStartDate = () => {
  let start = moment().add(1, "days");

  while (!isDayAllowed(start.toDate())) {
    start = start.add(1, "days");
  }

  return start.toDate();
};
